<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { connectBagObjects, connectLocations, getLocationChildDetails, patchLocationChilds, severityCode } from "@/services/api/location.api";
import { navigateToLocation } from "@/composables/general.js";
import DateInput from "@/components/general/DateInput.vue";
import BagSearch from "@/components/location/BagSearch.vue";

const { formatDateUtcDT } = useDateTimeFormatter();

setInteractionMode("eager");

const props = defineProps({
  id: {
    type: String,
    default: undefined,
  },
  locationReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
});

const selectedData = ref({
  locationReference: undefined,
  validFrom: undefined,
  validTill: undefined,
  modifiedDate: undefined,
  modifiedDescription: undefined,
});
const bagSelected = ref([]);
const isSaving = ref(false);

watch(
  () => props.id,
  () => {
    console.log("LocationSub, watch id ", props.locationReference, props.id);
    if (props.id) {
      fetchData();
    }
  },
  { immediate: true }
);

const bagHasSelection = computed(() => bagSelected.value.some((x) => x));
const allowSave = computed(() => bagHasSelection.value || selectedData.value.locationReference);
const modifiedDateFormatted = computed(() => formatDateUtcDT(selectedData.value.modifiedDate));

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  console.log("LocationSub, fetchData", props.id);
  const response = await getLocationChildDetails(props.locationReference, props.id);
  if (response && !response.error) {
    console.log("LocationSub, fetchData response ", response);
    selectedData.value = response.data;
  } else {
    proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
  }
}

async function selectedBag(addresses) {
  console.log("LocationSub selectedBag", addresses);
  bagSelected.value = addresses;
}

const obs = ref(null);

async function onSave() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    if (props.addModus) {
      await saveNewDetail();
    } else {
      await saveDetail();
    }
  }
  isSaving.value = false;
}

async function saveDetail() {
  console.log("LocationSub saveDetail");

  const response = await patchLocationChilds(props.locationReference, selectedData.value.id, selectedData.value.locationReference, {
    validFrom: selectedData.value.validFrom,
    validTill: selectedData.value.validTill,
    modifiedDescription: selectedData.value.modifiedDescription,
  });
  console.log("LocationSub, saveDetail response ", response);
  handleResponse(response);
}

async function handleResponse(response) {
  switch (response.severity) {
    case severityCode.none:
      proxy.$toaster.success("Gegevens zijn successvol gewijzigd.");
      break;
    case severityCode.hint:
    case severityCode.warning:
      proxy.$toaster.warning(response.message);
      break;
    case severityCode.error:
      proxy.$toaster.error("Bewaren van gegevens is mislukt! " + response.error);
      break;
  }
  if (response.severity <= severityCode.warning) {
    navigateToLocation(props.locationReference);
  }
}

async function saveNewDetail() {
  console.log("LocationSub saveNewDetail");
  let response;
  if (bagHasSelection.value) {
    const selection = bagSelected.value.map((el) => ({ buildingId: el.buildingId, residenceId: el.residenceId, numberId: el.numberId }));
    console.log("LocationSub saveNewDetail", selection, selectedData.value);

    response = await connectBagObjects(props.locationReference, selection, {
      validFrom: selectedData.value.validFrom,
      validTill: selectedData.value.validTill,
      modifiedDescription: selectedData.value.modifiedDescription,
    });
  } else {
    response = await connectLocations(props.locationReference, selectedData.value.locationReference, {
      validFrom: selectedData.value.validFrom,
      validTill: selectedData.value.validTill,
      modifiedDescription: selectedData.value.modifiedDescription,
    });
  }
  console.log("LocationSub saveNewDetail", response);
  handleResponse(response);
}

async function onCancel() {
  navigateToLocation(props.locationReference);
}
</script>

<template>
  <v-container fluid pa-0>
    <header class="app-welcome-message">
      <h2 v-if="addModus">Toevoegen van sublocatie</h2>
      <h2 v-else>Wijzigen van sublocatie</h2>
    </header>

    <v-row>
      <v-col>
        <ValidationObserver ref="obs">
          <v-form>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid>
                <header>Geldigheid</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig vanaf" rules="date_between|required" v-slot="{ errors }">
                      <date-input v-model="selectedData.validFrom" outlined persistent-placeholder label="Geldig vanaf" :errors="errors"></date-input>
                    </ValidationProvider>
                  </v-col>
                  <v-col>
                    <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.validFrom}`" v-slot="{ errors }">
                      <date-input v-model="selectedData.validTill" outlined persistent-placeholder label="Geldig tot" :errors="errors"></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Aanpassing</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field hide-details="auto" label="Omschrijving" outlined persistent-placeholder v-model="selectedData.modifiedDescription" :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="!addModus">
                  <v-col>
                    <v-text-field persistent-placeholder hide-details="auto" label="Door" v-model="selectedData.modifiedBy" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!addModus">
                  <v-col>
                    <v-text-field hide-details="auto" label="Datum" v-model="modifiedDateFormatted" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed" fluid>
                <header>Sub-locatie</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Sub locatie ID" rules="numeric" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        label="INNAX-ID"
                        outlined
                        persistent-placeholder
                        v-model="selectedData.locationReference"
                        :disabled="bagHasSelection"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>
          </v-form>
        </ValidationObserver>
        <bag-search v-show="addModus" @selected="selectedBag"></bag-search>
        <v-container fluid>
          <v-row>
            <v-col cols="auto" class="form-group">
              <v-btn class="primary" v-on:click="onSave()" :disabled="!allowSave || isSaving">
                <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                Opslaan
              </v-btn>
            </v-col>
            <v-col cols="auto" class="form-group">
              <v-btn v-on:click="onCancel()">
                <v-icon dark left>mdi-cancel</v-icon>
                Annuleren
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
